import { useState, useCallback, useMemo } from 'react'
import { useMeStore } from '~/modules/SDK/me/useMeStore'
import { useMount } from 'react-use'
import { getFirebaseStore } from '~/modules/SDK/firebase/getFirebase'
import { apirc } from '~/configs/apirc'

/** FireBase firestore "symbol_watch_list" */
export const useSymbolWatchList = (groupName: string) => {
  const firestore = getFirebaseStore()
  type Agent = typeof groupName
  type UserList = {
    [key in Agent]: string[]
  }
  const { meUserState } = useMeStore()
  const userCode = meUserState?.uid || undefined

  //firestore自選股 集合:symbol_watch_list */
  const collectionRef = firestore().collection('symbol_watch_list')
  const [symbolList, setSymbolList] = useState<UserList>()

  /** 群組 */
  const symbolListArray = symbolList?.[groupName] as string[]

  /** 獲取自選股清單 */
  const getSymbolList = useCallback(() => {
    if (userCode) {
      collectionRef.doc(userCode).onSnapshot(doc => setSymbolList(doc.data() as UserList))
    }
  }, [userCode, collectionRef])

  /** 第一次使用時創建xxxx_group空文件 */
  const creatValueFile = useCallback(() => {
    if (symbolListArray === undefined && userCode !== undefined) {
      collectionRef
        .doc(userCode)
        .set(
          {
            [groupName]: firestore.FieldValue.arrayUnion(),
          },
          { merge: true },
        )
        .then(() => {
          apirc.elk.sendToUserWatchlist(`首次進來 ${groupName}`, {
            result: `幫他在 ${groupName} 執行 firestore.FieldValue.arrayUnion()`,
          })
        })
    }
  }, [symbolListArray, userCode, collectionRef, groupName, firestore.FieldValue])

  /** 新增自選股 並設定陣列自選股檔數 */
  const addSymbol = useCallback(
    (symbol: string, listLength: number) => {
      if (
        symbolListArray === undefined ||
        (symbolListArray?.length < listLength && !symbolListArray?.includes(symbol))
      ) {
        const keep = [...(symbolListArray ?? []), symbol]
        collectionRef
          .doc(userCode)
          .update({
            [groupName]: firestore.FieldValue.arrayUnion(symbol),
          })
          .then(() => {
            apirc.elk.sendToUserWatchlist(`新增自選股商品 ${symbol} 到 ${groupName}`, {
              result: String(keep),
            })
          })
      }
      if (symbolListArray?.includes(symbol)) alert('自選股清單已有此商品!')
      if (symbolListArray?.length >= listLength) alert('自選股數量已達上限!')
    },
    [symbolListArray, collectionRef, userCode, groupName, firestore.FieldValue],
  )

  /** 移除選取的自選股 並設定陣列自選股檔數 */
  const removeSymbol = useCallback(
    (symbolNumber: string) => {
      const symbolListRemove = [...(symbolListArray as string[])]
      const index = symbolListRemove.indexOf(symbolNumber)
      symbolListRemove.splice(index, 1)
      collectionRef
        .doc(userCode)
        .update(
          {
            [groupName]: symbolListRemove,
          },
          // { merge: true },
        )
        .then(() => {
          apirc.elk.sendToUserWatchlist(`移除自選股商品 ${symbolNumber} 從 ${groupName}`, {
            result: String(symbolListRemove),
          })
        })
    },
    [collectionRef, groupName, symbolListArray, userCode],
  )

  /** 除全部自選股 並設定陣列自選股檔數 */
  const removeAllSymbol = useCallback(() => {
    if (symbolListArray !== undefined) {
      collectionRef
        .doc(userCode)
        .set(
          {
            [groupName]: [],
          },
          { merge: true },
        )
        .then(() => {
          apirc.elk.sendToUserWatchlist(`移除全部自選股商品 從 ${groupName}`, {
            result: `移除全部之前，他的商品為 ${String(symbolListArray)}`,
          })
        })
    }
  }, [collectionRef, groupName, symbolListArray, userCode])

  useMount(() => {
    getSymbolList()
    creatValueFile()
  })

  return useMemo(() => {
    return {
      state: {
        symbolListArray,
      },
      acts: { addSymbol, removeSymbol, removeAllSymbol, getSymbolList },
    }
  }, [addSymbol, removeSymbol, removeAllSymbol, symbolListArray, getSymbolList])
}
