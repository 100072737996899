import { css } from '@emotion/react'
import { ThemeOptions } from '@mui/material'
import { flex } from '~/modules/AppLayout/FlexGridCss'

export const scrollbarCss = css`
  width: 100%;
  height: 100%;
  ${flex.v.default};
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
    background-color: #cccccc;
  }
  &::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 5px;
    background-color: #999999;
  }
`

export const scrollbar2Css = css`
  width: 100%;
  height: 100%;
  ${flex.v.default};
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
    background-color: #555;
  }
  &::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 5px;
    background-color: #ccc;
  }
`

export const horizontalScrollbarCss = css`
  width: 100%;
  height: 100%;
  ${flex.v.default};
  overflow-y: auto;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #555;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    height: 4px;
    border-radius: 5px;
    background-color: #ccc;
  }
`

/**
 * # 幣安的 scrollbar 樣式
 *
 * ## 須要搭配 `<ThemeLayout />`
 *
 * @example
 *   ;<div css={scrollbarOfBinanceCssFn} />
 *
 *   //
 *   // 或
 *   //
 *   ;<div
 *     css={[
 *       scrollbarOfBinanceCssFn, // 這樣丟就可以配合其它客製的 CSSset
 *       css`
 *         outline: 1px solid red;
 *       `,
 *     ]}
 *   />
 */
export const scrollbarOfBinanceCssFn = (themeConfig: ThemeOptions): CSS => {
  return css`
    ::-webkit-scrollbar {
      width: 5px;
      height: 6px;
    }

    ${themeConfig.palette?.mode === 'light' &&
    css`
      ::-webkit-scrollbar-thumb {
        background: rgb(188, 193, 201);
        border-radius: 3px;
        height: 90px;
      }
    `}

    ${themeConfig.palette?.mode === 'dark' &&
    css`
      ::-webkit-scrollbar-thumb {
        background: rgb(94, 102, 115);
        border-radius: 3px;
        height: 90px;
      }
    `}
  `
}

export const generateScrollbar = (base: string, thumb: string) => css`
  &::-webkit-scrollbar {
    width: 5px;
    background-color: ${base};
  }
  &::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 5px;
    background-color: ${thumb};
  }
`
