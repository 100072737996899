import { createStore } from '~/store/createStore'
import { proxy, useSnapshot } from 'valtio'
import { VirtualExchangeHolding } from '~/modules/virtualExchange/useVirtualExchangeResource'

export type VirtualHoldingSortKey = keyof VirtualExchangeHolding

export type SignalrSortKey =
  | 'close'
  | 'open'
  | 'high'
  | 'low'
  | 'prevRef'
  | 'symbol'
  | 'tickQty'
  | 'volume'
  /**客製化: 漲跌點*/
  | 'change'
  /**客製化: 漲跌幅*/
  | 'changePrecent'
  | 'none'

export type FrontEndOrderKey = 'desc' | 'asc' | 'none'

export const useSortVirtualHoldingDataStore = proxy({
  sortKey: 'lastModifiedDatetime' as VirtualHoldingSortKey,
  orderKey: 'desc' as FrontEndOrderKey,
})

export const useSortSignalrDataStore = proxy({
  sortKey: '' as SignalrSortKey,
  orderKey: 'asc' as FrontEndOrderKey,
})
