import Big from 'big.js'
import { useRef } from 'react'
import { usePrevious } from 'react-use'

/**
 * 持續傳入「數字」返回該數字相較於前次傳入之比較
 *
 * @example
 *   const tickNumber = random(0, 1000)
 *
 *   const change = useQuoteUpdate(tickNumber)
 *
 *   console.info(change.upThanPrevious) // true 或 false
 *   console.info(change.downThanPrevious) // true 或 false
 *   console.info(change.sameThenPrevious) // true 或 false
 */
export const useTickChanges = (tickNumber = 0) => {
  const prevTickNumber = usePrevious(tickNumber) ?? 0
  const currentChanges = useRef(0)

  const currentChanges_ = Big(tickNumber).minus(prevTickNumber).toNumber()

  /**
   * 不要將 0 的變化，提供給開發者
   *
   * 這是因為有時 tickNumber 變化太過快速，以致於每次 render 之間產生非常多的 0，這種情況會導致 UI 無法有足夠時間來呈現「變化量」
   */
  if (currentChanges_ !== 0) {
    currentChanges.current = currentChanges_
  }

  const isUpThanPrev = tickNumber > prevTickNumber
  const isDownThanPrev = tickNumber < prevTickNumber
  const isSameThanPrev = tickNumber === prevTickNumber
  const update = {
    /** 「這次」與「前次」上升嗎？ */
    upThanPrevious: isUpThanPrev,
    /** 「這次」與「前次」下降嗎？ */
    downThanPrevious: isDownThanPrev,
    /** 「這次」與「前次」相同嗎？ */
    sameThenPrevious: isSameThanPrev,
    /** 「這次」的值 */
    current: tickNumber,
    /** 「這次」與「前次」相比之變化量 */
    currentChanges: currentChanges.current,
  }

  return update
}
